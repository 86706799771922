import { PageProps } from 'gatsby';
import React, { FC } from 'react';

// import Boutique from '../../core/sysext/Boutique';
import UserForm from '../../core/sysext/CMS/containers/Admin/containers/User/components/Form';
import UserList from '../../core/sysext/CMS/containers/Admin/containers/User/components/List';
import Backoffice from '../../core/sysext/CMS/containers/Backoffice';

const AdminUsers: FC<PageProps> = props => {
  const {
    location,
    params: { '*': star },
  } = props;

  return (
    <Backoffice location={location}>
      {star ? <UserForm docId={star} {...props} /> : <UserList {...props} />}
    </Backoffice>
  );
};

export default AdminUsers;
