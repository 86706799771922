import { FirebaseFirestore, UserType } from '@innedit/innedit';
import React, { FC, useEffect, useState } from 'react';

import ListItem from '../../../../../Boutique/components/new/Item';
import { ListItemProps } from '../../../../../Boutique/components/new/props';

const Item: FC<ListItemProps<UserType>> = ({
  docId,
  index,
  model,
  onClick,
}) => {
  const [doc, setDoc] =
    useState<FirebaseFirestore.DocumentSnapshot<UserType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  return (
    <ListItem
      actions={{ tooltip: docId, type: 'tooltip' }}
      contentClassName="flex-auto flex flex-row"
      displayActionId={false}
      doc={doc}
      index={index}
      onClick={onClick}
    >
      {doc.get('displayname')}
    </ListItem>
  );
};

export default Item;
